import Join from '../templates/Join.re'
import { graphql } from 'gatsby'

export default Join

export const query = graphql`
{

  page: markdownRemark (
    fields: {
      sourceInstanceName: { eq: "pages" },
      slug: { eq: "/classes/" }
    }
  ) {
    html
  }

  regions: allMarkdownRemark (
    limit: 100
    filter: {
      fields: { sourceInstanceName: { eq: "regions" }},
    }
    sort: { fields: [frontmatter___index], order: ASC }
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          id
          index
          longitude
          latitude
        }
        rawMarkdownBody
        html
      }
    }
  }

  locations: allMarkdownRemark (
    limit: 100
    filter: {
      fields: { sourceInstanceName: { eq: "locations" }},
    }
    sort: { fields: [frontmatter___index], order: ASC }
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          region
          timetable
          instructor
          address
          train
          fee
          feeDetails {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          document {
            name
            publicURL
          }
          phone
          email
          information
          index
          longitude
          latitude
        }
        rawMarkdownBody
        html
      }
    }
  }

  instructors: allMarkdownRemark (
    limit: 100
    filter: { fields: { sourceInstanceName: { eq: "instructors" }}}
    sort: { fields: [frontmatter___index], order: ASC }
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          position
          certificate
          phone
          email
          image {
            publicURL
          }
          index
        }
        rawMarkdownBody
        html
      }
    }
  }

}
`

// Strapi query
  // files: allFile(
    // filter: { sourceInstanceName: { eq: "uploads" } }
  // ) {
    // edges {
      // node {
        // name
        // publicURL
      // }
    // }
  // }

  // strapiSnippets(name: { eq: "classesInfo" }) {
    // name
    // content {
      // childMarkdownRemark {
        // html
      // }
    // }
  // }
  // allStrapiLocations(
    // limit: 100
    // sort: { fields: [index], order: ASC }
  // ) {
    // edges {
      // node {
        // id
        // name
        // description {
          // description
          // childMarkdownRemark {
            // html
          // }
        // }
        // timetable
        // instructor
        // address
        // train
        // fee
        // documents {
          // name
          // hash
          // ext
        // }
        // phone
        // email
        // info {
          // info
          // childMarkdownRemark {
            // html
          // }
        // }
        // index
        // place
        // longitude
        // latitude
        // feeDetails {
          // feeDetails
          // childMarkdownRemark {
            // html
          // }
        // }
      // }
    // }
  // }
  // allStrapiInstructors(
    // limit: 100
    // sort: { fields: [index], order: ASC }
  // ) {
    // edges {
      // node {
        // id
        // name
        // title
        // description {
          // description
          // childMarkdownRemark {
            // html
          // }
        // }
        // certificate
        // phone
        // email
        // photo {
          // hash
        // }
        // index
      // }
    // }
  // }
